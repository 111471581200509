
import PreviousLiveList from '@/modules/live/components/previous-live-list.vue';
import moment from 'moment';
// import firebase from 'firebase'
// import { vueVimeoPlayer } from 'vue-vimeo-player'
import { mapGetters, mapActions } from 'vuex';
import NoContent from '../../../shared/components/noContent.vue';
// var monitor = setInterval(function () {
//   var elem = document.activeElement;
//   if (elem && elem.tagName == 'IFRAME') {
//     clearInterval(monitor);
//   }
// }, 100);
export default {
  data() {
    return {
      latestLiveTime: null,
      mainView: null,
      videoPlayed: false,
      mainVideo:
        'https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2FSmouhaOfficial%2Fvideos%2F390949959469084%2F&show_text=false&width=560&t=0',
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_sm: 'layout/is_screen_sm',
      rows: 'live/rows',
    }),
  },
  components: {
    [PreviousLiveList.name]: PreviousLiveList,
  },
  method: {
    changeMainView(id) {
      console.log(this.mainView);
      this.mainView = this.rows.find(
        (e) => e.id == id,
      ).html;
      console.log(this.mainView);

      // this.rows.find(e => e.id == id).html
    },

    ...mapActions({
      doFetch: 'live/doFetch',
    }),
    doPlayVideo(record) {
      console.log('record', record);
      this.mainVideo = record;
      // doPlayVideo
    },
    playMainVideo() {
      this.videoPlayed = !this.videoPlayed;
      console.log('video', this.videoPlayed);
    },
  },

  async created() {
    await this.doFetch();
    console.log('this is live rows', this.rows);
    let first = new Date(this.rows[0].createdAt);
    let second = new Date();
    let third = second.getTime() - first.getTime();
    this.latestLiveTime = third / 1000 / 60 / 60;
    this.mainView = this.rows[0].html;
  },
};
