
export default {
   name: 'app-previous-live-list',
   data() {
      return {
         thumbStyle: {
            // right: "2px",
            borderRadius: "9px",
            backgroundColor: "#A8000F",
            width: "5px",
            opacity: 1,
         },

         // <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FFaragamerofficail%2Fvideos%2F458492482172148%2F&show_text=false&width=560" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
         // <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FFaragamerofficail%2Fvideos%2F859787294605372%2F&show_text=false&width=560" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
         videos: [
            "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2FSmouhaOfficial%2Fvideos%2F390949959469084%2F&show_text=false&width=560&t=0",
            "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2FSmouhaOfficial%2Fvideos%2F390949959469084%2F&show_text=false&width=560&t=0", "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2FSmouhaOfficial%2Fvideos%2F390949959469084%2F&show_text=false&width=560&t=0",
         ]
      }
   },
   methods: {
      doPlayVideo(record) {
         console.log('record', record);
         this.$emit('doPlayVideo', record)
      }
   },
   props: ['prev']

}
